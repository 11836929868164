//import Input from './clips/input.mov'
//import { MovesOct18 } from './clips/MovesOct18.js'
//import { MovesOct31 } from './clips/MovesOct31.js'
import { consoleLog } from './Platform.js'

const capitalize = s => s[0].toUpperCase() + s.slice(1)

const COMBO = ['Combo']

let Input

let id = 1

export class Move {
  constructor (move) {
    this.move = move
    this.id = ++id
  }

  toJSON() {
    return this.move
  }

  getDatabaseId() {
    return this.move.id
  }

  getOwner() {
    return null
  }

  getSource() {
    return this.move.src
  }

  getId() {
    return this.id
  }

  getTranslateX() {
    return this.move.translateX || 0
  }

  getTranslateY() {
    return this.move.translateY || 0
  }

  getTransform() {
    return this.move.transform 
  }

  isDisabled() {
    return !!this.move.disabled
  }
  
  getName() {
    return this.move.name
  }
  getDisplayName() {
    return this.move.displayName || this.getName()
  }
  getStartStation() {
    return this.move.startStation
  }
  getEndStation() {
    return this.move.endStation
  }
  getStartStance() {
    return this.move.startStance
  }
  getEndStance() {
    return this.move.endStance
  }
  getBallStart() {
    return this.move.ballStart
  }
  getBallEnd() {
    return this.move.ballEnd
  }
  getStartFrame() {
    return this.move.startFrame
  }
  getEndFrame() {
    return this.move.endFrame
  }
  getClip() {
    return this.move.clip
  }
  getImageSrc() {
    const c = this.move.clip
    const dot = c.lastIndexOf('.')
    return c.substring(0, dot) + '.png'
  }
  getHeight() {
    return this.move.height
  }
  getDirection() {
    return this.move.dir
  }
  getFrameCount() {
    return this.move.endFrame - this.move.startFrame
  }
  map(f) {
    return f(this, 0)
  }
  dup() {
    return new Move(this.move)
  }
  getNextMove() {
    return this.next || this
  }
  getPivotStart() {
    return move.pivotStart
  }
  getPivotEnd() {
    return move.pivotEnd
  }
  isComposite = () => false

  getParent = () => this.parent

  getCategories() {
    return this.move.categories || []
  }

  isSameMove(other) {
    return this.getClip() === other.getClip()
  }
  getHipsStart() {
    return this.moves.hipsStart
  }
  getHipsEnd() {
    return this.moves.hipsEnd
  }
}

class DelegatingMove {
  constructor (move) {
    this.move = move
  }
  getName() {
    return this.move.getName()
  }
  getStartStance() {
    return this.move.getStartStance()
  }
  getEndStance() {
    return this.move.getEndStance()
  }
  getBallStart() {
    return this.move.getBallStart()
  }
  getBallEnd() {
    return this.move.getBallEnd()
  }
  getStartFrame() {
    return this.move.getStartFrame()
  }
  getEndFrame() {
    return this.move.getEndFrame()
  }
  getClip() {
    return this.move.getClip()
  }
  getStartHeight() {
    return this.move.getStartHeight()
  }
  getEndHeight() {
    return this.move.getEndHeight()
  }
  getStartDirection() {
    return this.move.getStartDirection()
  }
  getEndDirection() {
    return this.move.getEndDirection()
  }
  getFrameCount() {
    return this.move.getFrameCount()
  }
  map(f) {
    return this.move.map(f)
  }
  dup() {
    throw "this needs overriding"
  }
  getNextMove() {
    return this.move.getNextMove()
  }
  getPivotStart() {
    return move.getPivotStart()
  }
  getPivotEnd() {
    return move.getPivotEnd()
  }
}

const isFinish = move => {
  return !move.getBallEnd() || move.getBallEnd() === 'both' || move.getBallEnd() === 'n/a' || move.getBallEnd() === 'none'
}

export class MoveAutocomplete {

  constructor (allMoves, selectedCat) {
    this.allMoves = allMoves
    this.selectedCat = selectedCat
  }

  generateDrill = () => {
    const moveArray = Object.values(this.allMoves).filter(move => !isFinish(move) && move.getStartStance() === 'neutral')
    return this.generateMove(moveArray,
                             8,
                             (move, availableMoves, output) =>  output.length > 1 && availableMoves.indexOf(output[0]) >= 0,
                             move => !isFinish(move))
  }

  generateFinish = () => {
    const moveArray = Object.values(this.allMoves).filter(move => move.getStartStation() === 'back-right' && move.getStartStance() === 'neutral')
    return this.generateMove(moveArray, 16, (move, availableMoves, output) => isFinish(move))
  }

  generateMove = (moveArray, maxLength, p, filt) => {
    const randomMove = (arr) => Math.floor(Math.random()*arr.length)
    let arr = moveArray
    const output = []
    const gen = (arr, output) => {
      const i = randomMove(arr)
      let start = arr[i]
      consoleLog('start', start.getName(), 'of', arr.length)
      let endStation = start.getStartStation()
      let ballEnd = start.getBallStart()
      let endStance = start.getStartStance()
      let current = start
      output.push(start)
      let { availableMoves } = this.getAvailableMovesImpl(null, start, null, null)
      if (filt) {
        availableMoves = availableMoves.filter(filt)
      }
      consoleLog(start.getName(), "availableMoves", availableMoves.map(x => x.getName()).join(', '))
      if (p(start, availableMoves, output)) {
        consoleLog("completed", output[0].getName())
        return 0
      }
      if (output.length < maxLength) {
        let iter = 0
        while (iter < 32) {
          if (availableMoves.length > 0) {
            switch (gen(availableMoves, output)) {
              case 1:
                return 1
              case 0:
                return 0
              case -1:
                break
            }
          } else {
            break
          }
          iter++
        }
      }
      arr.splice(i, 1)
      output.pop()
      consoleLog("backtracking to ", output.map(x => x.getName()).join(' + '))
      return -1
    }
    let iter = 0
    while (output.length < 2) {
      output.length = 0
      const result = gen(moveArray, output, 0)
      consoleLog("generateDrill", result, output.map(x => x.getName()).join(' + '))
      iter++
      if (iter > 32) {
        break
      }
    }
    return output
  }
  
  getAvailableMoves = (before, after) => {
    const startStation = null
    const endStation = (before || after) ? null : 'back-right'
    const endStance = (before || after) ? null : 'neutral'
    const result = this.getAvailableMovesImpl(before, after, startStation, endStation, endStance, true)
    consoleLog("get available moves", 'before', before, 'after', after, '=>', result.availableMoves.map(x => x.getName()).join(', '))
    return result
  }
  
  getAvailableMovesImpl = (before, after, startStation, endStation, endStance, returnCats) => {
    const selectedCat = this.selectedCat
    let availableMoves = []
    const availableCats =  {}
    if (!before) {
      before = {
	getStartStation: () => startStation,
	getStartStance: () => null,
	getBallStart: () => null
      }
    }
    if (!after) {
      after = {
	getEndStation: () => endStation,
	getEndStance: () => endStance,
	getBallEnd: () => null
      }
    }
    for (const id in this.allMoves) {
      const move = this.allMoves[id]
      if ((!after.getEndStance() || (after.getEndStance() !== 'n/a' && move.getStartStance() === after.getEndStance())) && (!after.getBallEnd() || move.getBallStart() === after.getBallEnd())) {
	if (!after.getEndStation() || after.getEndStation() === move.getStartStation()) {
	  if ((!before.getStartStance() || (move.getEndStance() !== 'n/a' && move.getEndStance() === before.getStartStance()) && (!before.getBallStart() || move.getBallEnd() === before.getBallStart()) && (!before.getStartStation() || before.getStartStation() === move.getEndStation()))) {
      		//consoleLog('matched', move.getName(), move.getCategories())		
              if (returnCats) {
		  move.getCategories().forEach(name => {
	              if (!selectedCat || selectedCat === name) {
			  let cat = availableCats[name]
			  if (!cat) {
			      cat = [move]
			      availableCats[name] = cat
			  } else {
			      cat.push(move)
			  }
		      }
		  })
	      } else {
                  availableMoves.push(move)
	      }
	  } else {
	      //consoleLog('no match', move.getName())
	  }
	}
      }
    }
    let cats
    if (returnCats) {
      cats = []
      const acats = Object.keys(availableCats)
      if (acats.length === 1) {
        availableMoves = availableCats[acats[0]]
      } else {
        for (const name of acats) {
	  const cat = availableCats[name]
	  if (cat.length > 1) {
	    cats.push(name)
	  } else {
	    availableMoves.push(cat[0])
	  }
        }
      }
      cats.sort((x, y) => {
        return x.localeCompare(y)
      })
    }
    availableMoves.sort((x, y) => {
      return x.getName().localeCompare(y.getName())
    })
    return { cats, availableMoves }
  }
}

class CrossMove extends Move {
  constructor(name, cross, thru, behind) {
    super(cross)
    this.className = name
    this.cross = cross
    this.thru = thru
    this.behind = behind
  }
  isCross() {
    return this.move === this.cross
  }
  isBehind() {
    return this.move === this.behind
  }
  isThru() {
    return this.move === this.thru
  }
  setCross() {
    this.move = this.cross
  }
  setBehind() {
    this.move = this.behind
  }
  setThru() {
    this.move = this.thru
  }
}

class InvertibleMove extends DelegatingMove {
  constructor (name, standard, inverted) {
    super(standard)
    this.name = name
    this.standard = standard
    this.inverted = inverted
  }

  getName() {
    return this.name
  }

  isInverted() {
    return this.move === this.inverted
  }

  invert() {
    this.move = this.move === this.standard ? this.inverted : this.standard
  }

  getInverted() {
    return this.inverted
  }
}

export const createMove = move => {
  if (move.moves) {
    const opts = move.opts || {}
    opts.id = move.id
    return new CompositeMove(move.name, move.moves.map(createMove), opts)
  }
  if (!move.clip.startsWith('http://')) {
    move.endFrame = (move.endFrame - move.startFrame) 
    move.startFrame = 0 + (move.startFrameOffset || 0)
    move.endFrame += (move.endFrameOffset  || 0)
    
  }
  return new Move(move)
}

export class CompositeMove {

  constructor(name, moves, opts) {
    this.id = ++id
    this.name = name
    this.moves = moves.map(x => x.dup()) || []
    this.frameCount = 0
    this.moves.map(x => {
      x.parent = this
      this.frameCount += x.getFrameCount()
    })
    this.link()
    this.opts = opts
  }

  getParent = () => this.parent
  
  isSameMove(other) {
    if (other.moves && other.moves.length === this.moves.length) {
      for (let i = 0; i < this.moves.length; i++) {
	const x = this.moves[i]
	const y = other.moves[y]
	if (!x.isSameMove(y)) {
	  return false
	}
      }
    }
    return false
  }

  getSource() {
    return null
  }

  getImageSrc() {
    return this.moves[0].getImageSrc()
  }

  getDatabaseId() {
    return this.opts.id
  }

  getOwner() {
    return this.opts.uid
  }
  setOwner (uid) {
    this.opts.uid = uid
  }

  toJSON() {
    const opts = clone(this.opts)
    delete opts.id
    delete opts.uid
    const id =  this.opts.id
    const json = {
      name: this.name,
      moves: this.moves.map(move => move.toJSON()),
      opts
    }
    if (id) {
      json.id = id
    }
    if (this.displayName) {
      opts.displayName = this.displayName
    }
    return json
  }

  getTranslateX() {
    return 0
  }

  getTranslateY() {
    return 0
  }

  getTransform() {
    return null
  }
  
  isDisabled() {
    return !!this.opts.disabled
  }
  
  getId () {return this.opts.id }

  getName = () => { return this.name }

  getDisplayName = () => { return this.displayName || this.getName() }

  

  getNextMove() {
    return this.next || this
  }

  link() {
    this.map((x, i) => {
      if (i > 0) {
	this.moves[i - 1].next = x
      }
    })
  }

  isComposite() {
    return true
  }
  map(f) {
    return this.moves.map(f)
  }
  dup() {
    return new CompositeMove(this.name, this.moves, this.opts)
  }
  getCategories() {
    return this.opts.categories || COMBO
  }
  getFrameCount() {
    return this.frameCount
  } 
  getStartMove() {
    return this.moves[0]
  }
  getEndMove() {
    return this.moves[this.moves.length - 1]
  }
  getStartStation() {
    return this.getStartMove().getStartStation()
  }
  getEndStation() {
    return this.getEndMove().getEndStation()
  }
  getStartStance() {
    return this.getStartMove().getStartStance()
  }
  getEndStance() {
    return this.getEndMove().getEndStance()
  }
  getBallStart() {
    return this.getStartMove().getBallStart()
  }
  getBallEnd() {
    return this.getEndMove().getBallEnd()
  }
  getStartFrame() {
    return this.getStartMove().getStartFrame()
  }
  getEndFrame() {
    return this.getStartFrame() + this.getFrameCount()
  }
  getStartHeight() {
    return this.getStartMove().getStartHeight()
  }
  getEndHeight() {
    return this.getEndMove().getEndHeight()
  }
  getStartDirection() {
    return this.getStartMove().getStartDirection()
  }
  getEndDirection() {
    return this.getEndMove().getEndDirection()
  }
  getPivotStart() {
    return move.getStartMove().getPivotStart()
  }
  getPivotEnd() {
    return getEndMove().getPivotEnd()
  }
  getHipsStart() {
    return getStartMove().getHipsStart()
  }
  getHipsEnd() {
    return getEndMove().getHipsEnd()
  }
}

const BaseMoves = []//MovesOct31

BaseMoves.sort((x, y) => {
  return x.startFrame - y.startFrame
})

const MoveIndex = {}
const AllMoves = []

const clone = x => JSON.parse(JSON.stringify(x))
let startFrame = 1

BaseMoves.forEach((x, i) => {
  x = clone(x)
  if (x.station) {
    x.startStation = x.endStation = x.station
  }
  if (!x.startStation) {
    x.startStation = 'back-right'
  }
  if (!x.endStation) {
    x.endStation = 'back-right'
  }
  x.id = capitalize(x.name.replace(/[ +]/g, '-').toLowerCase() + '-' + x.startStation)
  const move = new Move(x)
  AllMoves.push(move)
})

export const Moves = AllMoves

consoleLog("Moves", Moves)


const FPS = 30
export const frameToTime = frame => frame / FPS

const select = AllMoves.map(move => {
  //return `between(n,${move.startFrame},${move.endFrame})`
  const dur = move.getFrameCount()
  const startFrame = move.getStartFrame()
  const name = move.getDatabaseId()
  return `ffmpeg -i input.mov -ss ${frameToTime(startFrame)} -t ${frameToTime(dur)} ${name}.mov`
})

const cmd = select.join('\n')
consoleLog(JSON.stringify(AllMoves.map(x => {
  const y = clone(x.toJSON())
  y.clip = `https://storage.googleapis.com/basketball-780ce.appspot.com/BaseMoves/${x.getDatabaseId()}.mov`
  return y
}), null, ' '))
consoleLog(cmd)


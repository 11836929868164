import LibPhone from 'google-libphonenumber'
import phone from 'phone'
import isEmail from 'validator/lib/isEmail'
export const clone = x => JSON.parse(JSON.stringify(x))
export const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000))
export const capitalize = x => x ? x[0].toUpperCase() + x.slice(1) : x
const phoneUtil = LibPhone.PhoneNumberUtil.getInstance()
const owasp = require('owasp-password-strength-test')

export const randomElement = array => array[Math.floor(Math.random() * array.length)]

export const formatPhone = number => {
  const parsed = phoneUtil.parseAndKeepRawInput(number, 'US')
  return phoneUtil.format(parsed, LibPhone.PhoneNumberFormat.NATIONAL)
}

export const convertPhone = n => {
  let { countryCode, phoneNumber } = parsePhoneNumber(n)
  phoneNumber = '+' + countryCode + phoneNumber
  const converted = phone(phoneNumber)
  return converted[0]
}

export const validatePassword = pw => {
  const result = owasp.test(pw)
  return result
}

export const validateEmail = email => {
  return isEmail(email)
}

export const parsePhoneNumber = number => {
  try {
    const parsed = phoneUtil.parseAndKeepRawInput(number, 'US')
    return { countryCode: parsed.getCountryCode(), phoneNumber: parsed.getNationalNumber() }
  } catch (ignored) {
    return { countryCode: 1, phoneNumber: number }
  }
}

export const normalizePhone = n => {
  const { countryCode, phoneNumber } = parsePhoneNumber(n)
  return '+' + countryCode + phoneNumber
}

export const mergeFields = (...xs) => {
  const result = {}
  for (const x of xs) {
    for (const i in x) {
      result[i] = x[i]
    }
  }
  return result
}

export const joinWith = (a, s) => {
  let sep
  const result = []
  for (const x of a) {
    if (sep) result.push(sep)
    result.push(x)
    sep = s()
  }
  return result
}

export const search = (input, getKey, getTerms, q) => {
  const normalize = term => term.replace(/[&,()/-]/g, ' ').toLowerCase().trim()
  const origSearchTerm = normalize(q)
  const searchTerms = q ? origSearchTerm.split(/\s+/) : []
  const matches = {}
  const seen = {}
  for (const x of input) {
    const t = getTerms(x)
    const origTerm = normalize(t)
    const terms = origTerm.split(/\s+/)
    //console.log('terms', terms)
    let matched = 0
    if (origTerm === origSearchTerm) {
      matched += 10
    }
    if (terms.length > 1) {
      searchTerms.forEach(searchTerm => {
        if (origTerm.startsWith(searchTerm)) {
          matched += 5
        }
      })
    }
    const id = getKey(x)
    if (seen[id]) {
      return
    }
    seen[id] = true
    terms.forEach(term => {
      if (term.length < 2) return
      searchTerms.forEach(searchTerm => {
        if (!searchTerm) return
        if (term === searchTerm) {
          matched += 4
        }
        else if (term.startsWith(searchTerm)) {
          matched++
        }
        else if (searchTerm.startsWith(term)) {
          matched += 0.25
        }
        //console.log('matched', searchTerm, term, matched)
      })
    })
    if (matched > 0) {
      let match = matches[id]
      if (!match) {
        match = {
          key: t,
          value: x,
          match: matched
        }
        matches[id] = match
      } else {
        match.match += matched
      }
      //console.log('match', match)
    }
  }
  const results = Object.values(matches)
  results.sort((match1, match2) => {
    let cmp = -(match1.match - match2.match)
    if (!cmp) {
      const s1 = match1.key
      const s2 = match2.key
      cmp = s1.localeCompare(s2)
    }
    //console.log(s1, s2, cmp)
    return cmp
  })
  return results.map(x => x.value)
}

export const formatAddress = address_components => {
  const getShortName = (type) => {
    const c = address_components.find(x => x.types.find(y => y === type))
    return c ? c.short_name : ''
  }
  let streetNumber = getShortName('street_number')
  const subpremise = getShortName('subpremise')
  if (subpremise) streetNumber += ' ' + subpremise
  const street = getShortName('route')
  const city = getShortName('locality')
  const state = getShortName('administrative_area_level_1')
  const zip = getShortName('postal_code')
  const address = `${streetNumber} ${street}, ${city}, ${state} ${zip}`
  return address
}

export const formatDistance = meters => {
  let miles = (meters * 0.000621371).toFixed(1)
  if (miles.endsWith('.0')) {
    const dot = miles.lastIndexOf('.')
    miles = miles.substring(0, dot)
  }
  return `${miles} mi`
}

export const arrayEq = (x, y) => {
  if (!x) {
    return !y
  } else if (!y) {
    return false
  }
  if (x.length !== y.length) {
    return false
  }
  for (let i = 0; i < x.length; i++) {
    if (x[i] !== y[i]) {
      return false
    }
  }
  return true
}

class DataChannel {
  constructor (peer, dc) {
    this.peer = peer
    this.dc = dc
    this._isOpen = false
    this.listen("message", data => {
      console.log("message", data)
      if (typeof(data) === 'string') {
        this._onMessage(data)
      } else {
        this._onBinaryMessage(data)
      }
    }, 'data')
    this.listen('open', () => {
      this._isOpen = true
      debugger
      if (this._onOpen) this._onOpen(this)
    }, 'target')
    this.listen('close', () => {
      this._isOpen = false
      if (this._onClosed) this._onClosed(this)
    }, 'target')
  }
  listen(name, f, field) {
    this.dc.addEventListener(name, e => f(field ? e[field] : e))
  }
  getLabel() {
    return this.dc.label
  }
  isOpen = () => this._isOpen
  close() {
    this._isOpen = false
    this.dc.close()
  }
  sendMessage = message => {
    return this.dc.send(message)
  }
  sendMessageBinary = message => {
    return this.dc.send(message)
  }
  onMessage(f) {
    this._onMessage = f
  }
  onBinaryMessage(f) {
    this._onBinaryMessage = f
  }
  onClosed(f) {
    this._onClosed = f
  }
  onOpen(f) {
    this._onOpen = f
  }
}

class PeerConnection {
  constructor (name, opts) {
    const { iceServers } = opts
    const config = {
      iceServers: iceServers.map(url => {
        if (url.startsWith('turn:')) {
          //"turn:e3176f3bf3e717d0b120235fc45244c87b5ee0fb374805cf37d9468154041367:Z6X6K0g0PzsufNZQ2Khg3ogjRN2/HoBFpDwujqk3fhU=@global.turn.twilio.com:3478?transport=udp"
          const [user, frag] = url.split('@')
          const [turn, username, credential] = user.split(':')
          return {
            username,
            credential,
            urls: ['turn:'+frag],
          }
        } else {
          return {
            urls: [url]
          }
        }
      })
    }
    console.log("CONFIG", config)
    this.pc = new RTCPeerConnection(config) 
  }
  listen(name, f, field) {
    this.pc.addEventListener(name, e => f(field ? e[field] : e))
  }
  onLocalDescription(f) {
    this._onLocalDescription = f
  }
  onLocalCandidate(f) {
    this.listen('icecandidate', x => {
      if (x) f(x.candidate, x.sdpMid || '')
    }, 'candidate')
  }
  onDataChannel(f) {
    this.listen('datachannel', dc => {
      f(new DataChannel(this, dc))      
    }, 'channel')
  }
  onStateChange(f) {
    this.listen('connectionstatechange',
                c => {
                  console.log(c.connectionState)
                  f(c.connectionState)
                }, 'target')
                
  }
  onGatheringStateChange = f => {
    this.listen('icegatheringstatechange', connection => {
      console.log(connection.iceGatheringState)
      f(connection.iceGatheringState)
    }, 'target')
  }
  setLocalDescription = async type => {
    if (type === 'Offer') {
      const offer  = await this.pc.createOffer()
      await this.pc.setLocalDescription(offer)
      this._onLocalDescription(offer.sdp, 'offer')
    } else {
      const answer = await this.pc.createAnswer()
      await this.pc.setLocalDescription(answer)
      this._onLocalDescription(answer.sdp, 'answer')
    }
  }
  createDataChannel = (label, opts) => {
    const dc = this.pc.createDataChannel(label, opts)
    return new DataChannel(this, dc)
  }
  close = () => {
    return this.pc.close()
  }
  setRemoteDescription = (sdp, type) => {
    return this.pc.setRemoteDescription({type, sdp})
  }
  addRemoteCandidate = (candidate, mid) => {
    return this.pc.addIceCandidate({ candidate, sdpMid: mid})
  }
}

export const nodeDataChannel = {
  PeerConnection
}



import {openDB} from 'idb'

export class LinkPreviewer {

  constructor(firebase) {
    this.firebase = firebase
    this.linkPreviews = {}
    this.linkPreviewQueue = {}
    openDB("linkPreviews", 1, {
      upgrade(db) {
        db.createObjectStore("links")
      }
    }).then(db => {
      this.db = db
    }).catch(err => {
      console.error(err)
    })
  }

  mediaTypeNotAvailable = {
    mediaType: "n/a",
  }

  previewInProgress = {
    mediaType: "n/a",
  }

  nopreview = ["google.com", "atlassian.net", "github.com", "microsoft.com", ".md", "gmail.com", "idmsa.apple.com"]

  waiting = {}

  getExistingLinkPreview = (url, k) => {
    if (!url.startsWith("http")) {
      return this.mediaTypeNotAvailable
    }
    if (url.startsWith(window.origin)) {
      return this.mediaTypeNotAvailable
    }
    const u = new URL(url)
    for (var i = 0; i < this.nopreview.length; i++) {
      if (u.hostname.endsWith(this.nopreview[i])) {
        return this.mediaTypeNotAvailable
      }
    }
    if (this.nopreview[u.hostname]) {
      return this.mediaTypeNotAvailable
    }
    const addToQueue = k => {
      if (k) {
        let waiters = this.linkPreviewQueue[url]
        if (!waiters) {
          waiters = [k]
          this.linkPreviewQueue[url] = waiters
        } else  {
          waiters.push(k)
        }
        console.log('waiting for link preview', waiters.length)
      }
    }
    const preview = this.linkPreviews[url]
    if (preview) {
      if (preview === this.previewInProgress) {
        addToQueue(k)
        return null
      }
      return preview
    } 
    this.linkPreviews[url] = this.previewInProgress
    addToQueue(k)
    this.getLinkPreview(url).then(response => {
      const waiters = this.linkPreviewQueue[url]
      if (waiters) {
        delete this.linkPreviewQueue[url]
      }
      if (!response) {
        return
      }
      switch (response.mediaType) {
        case "video.other": 
        case "image":
        case "video":
          break
        default:
          //response = this.mediaNotAvailable
      }
      if (!response.siteName) {
        function getDomain(url, subdomain) {
          subdomain = subdomain || false
          
          url = url.replace(/(https?:\/\/)?(www.)?/i, '')
          
          if (!subdomain) {
            url = url.split('.')
            
            url = url.slice(url.length - 2).join('.')
          }
          
          if (url.indexOf('/') !== -1) {
            return url.split('/')[0]
          }
          
          return url
        }
        response.siteName = getDomain(url, true)
      }
      this.addLinkPreview(url, response)
      if (waiters) waiters.map(k => k(response))
    }).catch(err => {
      console.log("error getting link preview for url: ", url, ": ", err)
    })
    return null
  }

  addLinkPreview(url, response) {
    this.linkPreviews[url] = response
  }

  getLinkPreview = url => {
    if (this.linkPreviews[url] && this.linkPreviews[url] !== this.previewInProgress) return Promise.resolve(this.linkPreviews[url])
    const getFromServer  = () => {
      const fun = this.firebase.functions().httpsCallable('getLinkPreview')
      return fun({url}).then(response => {
        let json = response.data.linkPreview
        console.log("got link preview response: ", json)
        if (!json) {
          json = this.mediaTypeNotAvailable
        }
        this.linkPreviews[url] = json
        return this.db.put("links", JSON.stringify(json), url).then(() => json).catch(err => {
          console.error(err)
        })
      })
    }
    return this.db.get("links", url).then(json => {
      if (json) {
        const result = JSON.parse(json)
        console.log("got link preview from db: ", result)
        if (result) {
          return result
        }
      }
      return getFromServer()
    }).catch(err => {
      console.log(err)
      return getFromServer()
    })
  }
}

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import Cross from '../../assets/Icons/Cross_1.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Check from '../../assets/Icons/Tick.svg'
import BxSpinner from '../../assets/Spinners/SpinnerShape.svg'
import BxSearch from '../../assets/Icons/Trash.svg'
import BxCross from '../../assets/Icons/Cross_1.svg'
import { isDesktop } from '../../classes/Platform.js'
import BxRight from '../../assets/Icons/Forward.svg'
import CurrencyInput from '../CurrencyInput'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import owasp from "owasp-password-strength-test";
import Cleave from 'cleave.js/react'
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.i18n.js'
import Util from 'cleave.js/src/utils/Util';
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'
import PhoneInput from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import ClickAwayListener from 'react-click-away-listener'
import { BnSelectionList } from '../Button'
import googleLibPhoneNumber from 'google-libphonenumber'
const PNF = googleLibPhoneNumber.PhoneNumberFormat;
const AsYouTypeFormatter = googleLibPhoneNumber.AsYouTypeFormatter
const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

export const parsePhoneNumber = number => {
  try {
    const parsed = phoneUtil.parseAndKeepRawInput(number, 'US')
    return {countryCode: parsed.getCountryCode(), phoneNumber: ''+parsed.getNationalNumber()};
  } catch (ignored) {
    return {countryCode: 1, phoneNumber: number};
  }
}

export class SelectionList extends Component {
  constructor(props) {
    super(props);
  }
  
  selectOption = value => {
    let newValue = value;
    let opt
    if (this.props.multiSelect) {
      const existing = this.props.value.find(x => x == value);
      if (existing) {
        newValue = this.props.value.filter(x => x != value);
      } else {
        newValue = this.props.value.concat([newValue]);
      }
    } else {
      opt = this.props.options.find(opt => opt.value == newValue)
    }
    this.props.select(newValue, opt)
  }

  render() {
    return <div className='uiSelectionList' style={this.props.visible ? null: {display: "none"}}>
      {this.props.options.map((opt, i) => {
        let selected;
        if (this.props.multiSelect) {
          selected = this.props.value.find(x => x == opt.value);
        } else {
          selected = opt.value == this.props.value;
        }
        //debugLog("opt.value: ", opt.value, " = ", this.props.value, " => ", selected);
        const className = "uiSelectionListOption" + (selected ? " uiSelectionListOptionSelected" : "");
        return <div key={opt.name} className={className} onClick={()=>this.selectOption(opt.value)}>
          <div className={'uiSelectionListOptionName'}>{opt.name}</div>
          <div className={'uiSelectionListOptionRight'}>
          <div className={'uiSelectionListOptionValue'}>{opt.value}</div>
            <div key='check' className={'uiSelectionListOptionCheck'} style={selected ? null : { visibility: 'hidden'} }><ReactSVG src={Check}/></div>
          </div>
          </div>
      })}
    </div>
  }
}

const regionCode = {
  "1": "US"
}

const getRegionCode = countryCode => {
  const result = regionCode[String(countryCode)]
  return result
}

const countryCodeOptions = getCountries().map(country => {
  const code = getCountryCallingCode(country)
  const opt = {
    name: en[country],
    value: code
  }
  if (!regionCode[code]) {
    regionCode[code] = country
  }
  return opt;
});

countryCodeOptions.sort((a, b) => {
  if (a.name == "United States") return -1; else if (b.name == "United States") return 1;
  return a.name.localeCompare(b.name);
});


const debugLog = (...args) => {
  //console.log.apply(null, args)
}

// hacks for another npm package fail
class Cleave1 extends Cleave {
  constructor(props) {
    super(props);
    const cls = Cleave1;
    const self = this;
    self.onFocus = event => {
      var owner = this, pps = owner.properties;
      if (pps.prefix && pps.noImmediatePrefix && !event.target.value) {
        owner.onInput(pps.prefix);
      }
      //event.target.rawValue = owner.getRawValue();
      event.target.value = pps.result;
      owner.registeredEvents.onFocus(event);
      Util.fixPrefixCursor(owner.element, pps.prefix, pps.delimiter, pps.delimiters);
    }
    self.onChange = event => {
      var owner = this,
          pps = owner.properties;
      owner.onInput(event.target.value);
      //event.target.rawValue = owner.getRawValue();
      event.target.value = pps.result;
      owner.registeredEvents.onChange(event);
    }
    self.onBlur = event => {
      var owner = this, pps = owner.properties;
      
      //event.target.rawValue = owner.getRawValue();
      event.target.value = pps.result;
      
      owner.registeredEvents.onBlur(event);
    }
    const prev = self.componentWillReceiveProps
  }

  /*
  componentDidMount() {
    super.componentDidMount()
    this.state.value = this.props.value
    this.forceUpdate()
  }
*/

}

// hacks for sorry npm fails
class MyCurrencyInput extends CurrencyInput {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    return;
  }

  setInput = input => {
    if (!this.theInput && input) {
      this.theInput = input;
      const f = input.setSelectionRange;
      input.setSelectionRange = (start, end) => document.activeElement == input ? f.apply(input, [start, end]) : null;
      input.onfocus = e => {
        input.setSelectionRange(0, 999)
      }
      if (isDesktop() && this.props.autoFocus) {
        input.setSelectionRange(0, 999)
        input.focus()
      }
    }
  }

  render() {
    return <input ref={this.setInput} type={this.props.inputType} value={this.state.maskedValue} onChange={this.handleChange} onFocus={this.handleFocus} {...this.state.customProps}/>
  }
}

export class BnTextArea extends Component {
    setRef = ref => {
	if (!this.ref) {
	    this.ref = ref
	    if (this.ref) {
		debugger
		if (this.props.autoFocus && isDesktop()) {
		    this.ref.setSelectionRange(0, 9999)
		    this.ref.focus()
		}
	    }
	}
    }
    render() {
	const props = this.props
	const { placeholder, onChange, form, name, autoFocus, setRef } = props
	const shouldAutoFocus = isDesktop() && autoFocus
	const onChangeTextArea = e => onChange(name, e.target.value)
	const value = form[name]
	return <div className='bnTextArea'>
		   <textarea ref={this.setRef} autoFocus={shouldAutoFocus} value={value} placeholder={placeholder} onChange={onChangeTextArea}/>
	       </div>
    }
}

export const BnBoolInput = props => {
  const { label, form, name } = props
  const onChange = e => props.onChange(name, e.target.checked)
  return <div className='bnBoolInput'>
    <div className='bnBoolInputLabel'>{label}</div>
    <mobiscroll.Switch theme='ios' checked={!!form[name]} onChange={onChange}/>
    </div>
}

export const BnNegBoolInput = props => {
  const { label, form, name } = props
  const onChange = e => props.onChange(name, e.target.checked)
  return <div className='bnBoolInput bnNegInput'>
    <div className='bnBoolInputLabel'>{label}</div>
    <mobiscroll.Switch theme='ios' checked={!!form[name]} onChange={onChange}/>
    </div>
}

export class BnInputField extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  onChange = e => {
    if (this.props.onChange){ 
      this.props.onChange(this.props.name, e.target.value)
    }
  }

  setRef = x => {
    if (x) {
      if (this.props.autoComplete) {
        x.autocomplete = this.props.autoComplete
      }
      if (this.props.autoFocus && isDesktop()) {
        x.focus()
      }
    }
  }

  onClickCountryCode = () => {
    if (this.props.onChange) {
      this.setState({selectingCountryCode: !this.state.selectingCountryCode});
    }
  }

  dismissCountryCodeSelection = () => {
    this.setState({selectingCountryCode: false});
  }

  onChangeCountryCode = value => {
    this.props.onChange(this.props.countryCodeName || 'countryCode', value);
    this.setState({selectingCountryCode: false});        
  }

  handlePhoneNumberInput = e => {
    const props = this.props;
    props.onChange(props.name, e.target.value); 
  }

  render() {
    let clear
    if (this.props.clear || this.props.onChange) {
      if (this.props.form[this.props.name]) {
        clear = () => {
          return this.props.clear ? this.props.clear() : this.onChange({target: {value: ''}})
        }
      }
    }
    let busy = this.props.busy
    let className = 'bnInputFieldInputContainer'
    if (!this.props.clear) {
      className += ' bnInputFieldInputContainerNoClear'
    }
    if (this.props.formErr && this.props.formErr.field === this.props.name) {
      className += ' bnInputFieldErr'
    }
    return <div className={className}>
             {this.renderField()}
             {(busy || clear) &&<div className='bnInputFieldClear' onClick={clear}>
               <ReactSVG src={busy ? Spin: Cross}/>
             </div>}
           </div>
  }

  renderField() {
    const value = this.props.form[this.props.name]
    let className = 'bnInputField'
    if (this.props.type === 'currency') {
      const props = this.props
      return <div className='bnCurrencyInput'>
               <div className='bnCurrencyInputLabel'>{props.label}</div>
               <MyCurrencyInput autoFocus={this.props.autoFocus} allowEmpty={false} precision={0} value={value || '0'} prefix={'$'} currency='USD' className='bnCurrencyInputInput' onChangeEvent={(e, maskedvalue, floatvalue) => {
                                  const ev = {
                                    target: {
                                      value: floatvalue
                                    }
                                  }
                                  this.onChange(ev)
                                }}
               />
             </div>
    }
    if (this.props.type === 'tel') {
      const props = this.props
      const handlePhoneNumberInput = e => {
        props.onChange(props.name, e.target.value); 
      }
      const setRef = x => {
        this.setRef(x)
      }
      let phoneRegionCode = getRegionCode(this.props.form.countryCode)
      const value = this.props.form[this.props.name]
      console.log("value", value)
      console.log("phone region", phoneRegionCode)
      className += ' bnInputFieldPhoneInput'
      return <div className='bnInputFieldPhoneInputField'>
          <ClickAwayListener onClickAway={this.dismissCountryCodeSelection}>
          <div className='bnInputFieldPhoneInputCountryCodeInput'>
          <div className='bnInputFieldPhoneInputCountryCode' onClick={this.onClickCountryCode}>
          +{this.props.form.countryCode}
        </div>
          <SelectionList select={this.onChangeCountryCode} options={countryCodeOptions} selected={this.props.countryCode} visible={this.state.selectingCountryCode} value={this.props.form.countryCode}/>
          </div>
          </ClickAwayListener>
               <div className={className}>
                 <PhoneInput
                   disabled={!this.props.onChange}
                   initialValueFormat={'national'}
                   country={phoneRegionCode}
                   placeholder={this.props.label} value={value} onChange={value => {
                     console.log("new value", value)
                     this.props.onChange(this.props.name, value)
                   }}
                 />
               </div>
             </div>
    }
    let pattern
    let inputmode
    if (this.props.type === 'number') {
      pattern = '[0-9]*'
      inputmode = 'numeric'
    }
    return <div className={className}><input className='bnInputFieldInput' ref={this.setRef} disabled={!this.props.onChange} placeholder={this.props.label} value={value} type={this.props.type} autocomplete={this.props.autocomplete} pattern={pattern} inputmode={inputmode} onChange={this.onChange}/>
      </div>
  }
}

export class BnInputFieldSeparator extends Component {
  constructor (props) {
    super(props)
  }

  render() {
    return <div className='bnInputFieldSeparator'/>
  }
}

export class BnSearchField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }
  searchId = 0
  onChange = async e => {
    const id = ++this.searchId
    this.setState({
      busy: id,
    })
    console.log("SEARCH", e.target.value)
    await this.props.search(e.target.value)
    if (this.state.busy === id) {
      this.setState({
        busy: null
      })
    }
  }

  setRef = x => {
    if (x) {
      if (this.props.autoFocus && isDesktop()) {
        x.focus()
      }
    }
  }
  
  render() {
    const value = this.props.searchTerm
    const clear = () => this.props.search('')
    let className = 'bnSearchFieldIcon'
    let icon = BxSearch
    if (this.state.busy) {
      className += ' bnBusy'
      icon = BxSpinner
    }
    return <div className={'bnSearchField'}>
      <div className={className}><ReactSVG src={icon}/></div>
      <div className='bnSearchFieldInput'><input ref={this.setRef} placeholder={this.props.label || 'Search'} value={value} onChange={this.onChange}/></div>
      <div className='bnSearchFieldIcon bnClickable' onClick={clear} style={value ? null : { visibility: 'hidden'}}><ReactSVG src={BxCross}/></div>
      </div>
  }
}

export const BnDateInput = props => {
  const { label, form, name, onChange, dateFormat, dateWheels, onInit, onClick } = props
  const value = form[name]
  const onInitDate = (e, i) => {
    onInit(i)
  }
  const onChangeDate = (e, instance) => {
    const val = instance.getVal()
    console.log(val)
    onChange(name, val)
  }
  const dateInput = <div key='dateInput' className='bnDateInput'><mobiscroll.Date  onInit={onInitDate} theme={'ios'} value={value} dateFormat={dateFormat || ' MMM D dd'} height={45} rows={5} onSet={onChangeDate}  dateWheels={dateWheels || 'MD dd'}><input tabIndex={-1}/></mobiscroll.Date></div>
  return  <div className={'bnPageButton'} onClick={onClick}>
            <div className='bnPageButtonLeft'>
              <div className='bnPageButtonLabel'>{label}</div>
            </div>
            <div className='bnPageButtonRight'>
              <div className='bnPageButtonCount'>{dateInput}</div>
              <div className='bnPageButtonIcon'><ReactSVG src={BxRight}/></div>
            </div>
          </div>
}
